.project {
    background-color: $off-white;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.06), 0 2px 5px 0 rgba(0,0,0,.2);
    border-radius: 10px;
    margin: 0px;
    overflow: hidden;
    padding: 0px;
    width: 70%;
}

.project-image {
    height: 50%;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
        transition: transform 0.5s linear;
        &:hover {
            transform: scale(1.5)
        }
    }
    
}
.project-content {
    color: $dark-grey;
    display: flex;
    flex-direction: column;
    padding: $m-size $l-size;
}

.project-title {
    font-weight: bold;
    font-size: $m-size;
    padding: 10px 0;
}


.demo {
    text-decoration: none;
}

.technology {
    padding: 0 0 0 16px;
}
