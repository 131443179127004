@import 'sweetalert2/src/sweetalert2.scss';
@import './base/settings';
@import './base/base';
@import './components/content-container';
@import './components/header';
@import './components/about';
@import './components/background';
@import './components/buttons';
@import './components/project';
@import './components/projects';
@import './components/experience';
@import './components/skills';
@import './components/general';
@import './components/skillsParallax';
@import './components/contact';
@import './components/modal';
@import './components/footer';
@import './components/animation';