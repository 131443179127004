.section-heading {
    display: flex;
    justify-content: center;
    h1 {
        display: inline-block;
        border-bottom: 2px solid $dark-grey;
    }
}

.section-heading--about {
    display: flex;
    justify-content: center;
    h1 {
        display: inline-block;
        border-bottom: 2px solid $blue;
    }
}

.section-heading--contact {
    color: $off-white;
    display: flex;
    justify-content: center;
    h1 {
        display: inline-block;
        border-bottom: 2px solid $blue;
    }
}

