// Colors
$dark-grey: #333;
$grey: #666;
$blue: #1c88bf;
$light-blue: rgb(68,174,228);
$dark-blue: #364051;
$off-white: #f7f7f7;
$green: #2b704f;
$brown: #8B4513;
// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$ml-size: 2.0rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
