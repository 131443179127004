.experience-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.experience-item {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
    top: 0;
    transition: top 0.5s, box-shadow 0.5s;
    &:hover {
        top: -10px;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,.06), 0 2px 5px 0 rgba(0,0,0,.2);
  }
  a {
    display: flex;
    justify-content: center;
    img {
      width: 90%;
      height: 90%;
    }
  }
}
@media (min-width: $desktop-breakpoint) {
  .experience-container {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
}
